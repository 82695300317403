/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";

import "./UserManagement.scss";
import { useAppDispatch, useTypedSelector } from "../../../store/app.store.tsx";
import { User } from "../../../interfaces/user.interface.tsx";
import { Button } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { clearSelectedUser, fetchUsers } from "../../../store/user.reducer.tsx";
import { Flex, Input, Select, Table, TableProps } from "antd";

export const UserManagement = (props: { token: string }) => {
  const dispath = useAppDispatch();
  const navigate = useNavigate();
  const { token } = props;

  const loading = useTypedSelector((state) => state.user.fetchingUser);
  const error = useTypedSelector((state) => state.user.errorFetchingUser);
  const users = useTypedSelector((state) => state.user.users);
  const [filteredUsers, setFilteredUsers] = useState<any[]>(users);
  const [searchText, setSearchText] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("first_name");
  const userTableHeaders: TableProps<User>["columns"] = [
    {
      key: "first_name",
      title: "First Name",
      dataIndex: "first_name",
      showSorterTooltip: { target: "full-header" },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase()),
      onFilter: (value, record) =>
        record.first_name
          .toLowerCase()
          .startsWith(value.toString().trim().toLowerCase()),
      filterSearch: true,
    },
    {
      key: "middle_name",
      title: "Middle Name",
      dataIndex: "middle_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a.middle_name.toLowerCase().localeCompare(b.middle_name.toLowerCase()),
    },
    {
      key: "last_name",
      dataIndex: "last_name",
      title: "Last Name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase()),
    },
    {
      key: "email_address",
      dataIndex: "email_address",
      title: "Email",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a.email_address
          .toLowerCase()
          .localeCompare(b.email_address.toLowerCase()),
    },
    {
      key: "active",
      dataIndex: "active",
      title: "Active",
      render: (data) => (Boolean(data) ? "Yes" : "No"),
    },
    {
      key: "registered",
      dataIndex: "registered",
      title: "Registered",
      render: (data) => (Boolean(data) ? "Yes" : "No"),
    },
  ];

  useEffect(() => {
    if (loading === "IDLE") {
      dispath(fetchUsers({ token }));
    }
    return () => {
      dispath(clearSelectedUser());
    };
  }, [dispath, loading, token]);

  useEffect(() => {
    if (searchField.trim() === "") {
      setFilteredUsers(users);
    } else {
      setFilteredUsers([
        ...users.filter((record) =>
          record[searchField]
            .toLowerCase()
            .startsWith(searchText.toLowerCase().trim())
        ),
      ]);
    }
  }, [searchText, searchField, users]);

  return (
    <div className="user-management">
      <section className="header">
        <h1 className="page-title">Users Management</h1>
        <Button variation="primary" onClick={() => navigate("/users/new")}>
          Add User
        </Button>
      </section>
      {loading === "FAILED" && (
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}
      <Flex gap={16} style={{ marginBottom: "1rem" }}>
        <Input
          placeholder="Search for..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Select
          options={[
            { label: "First Name", value: "first_name" },
            { label: "Last Name", value: "last_name" },
            { label: "Email Address", value: "email_address" },
          ]}
          value={searchField}
          onChange={(value) => setSearchField(value)}
          style={{ width: "30%" }}
        />
      </Flex>
      <Table<User>
        columns={userTableHeaders}
        dataSource={filteredUsers}
        showSorterTooltip={{ target: "sorter-icon" }}
        onRow={(record) => ({
          onClick: () => navigate(`/users/${record.user_id}`),
        })}
        loading={loading === "PENDING"}
        rowKey={(record) => `${record.user_id}`}
      />
    </div>
  );
};
